import React, {useEffect} from "react";
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import EditPlayer from "./components/EditPlayer";
import CreatePlayer from "./components/CreatePlayer";
import ListPlayers from "./components/ListPlayers";
import Results from "./components/Results";
import Footer from "./components/Footer";
import CreateResult from "./components/CreateResult";
import Header from "./components/Header";
import tlo2 from "../src/images/tlotenis.jpg"
import Cookies from "js-cookie"


function App() {

    const [auth, setAuth] = React.useState(false)

    const readCookie = () => {
        const user = Cookies.get("auth")
        if(user) {
            setAuth(true);
        }
    }

    useEffect(() => {
        readCookie();
    }, []);

    return (
        <>
            <div className="App" style={{backgroundImage: `url(${tlo2})`,backgroundAttachment: 'fixed', minHeight: '100vh'}}>
                <BrowserRouter>
                    <Header/>
                    <Routes >
                        <Route index element={<ListPlayers auth={auth}/>}/>
                        <Route path="dodaj/zawodnika" element={<CreatePlayer/>}/>
                        <Route path="wyniki/dodaj" element={<CreateResult />}/>
                        <Route path="wyniki" element={<Results auth={auth}/>}/>
                        <Route path="zawodnik/:id/edycja" element={<EditPlayer auth={auth}/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <Footer auth={auth}/>
        </>
    );
}

export default App;
